import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IOBroker on Raspberry Pi",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IOBroker on Raspberry Pi' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "iobroker-on-raspberry-pi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-on-raspberry-pi",
        "aria-label": "iobroker on raspberry pi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IOBroker on Raspberry Pi`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#iobroker-tutorials"
        }}>{`ioBroker Tutorials`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`ioBroker (`}<a parentName="p" {...{
        "href": "http://iobroker.net"
      }}>{`ioBroker Website`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/ioBroker/ioBroker"
      }}>{`Github`}</a>{`) is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. The following are a few tutorials that will show how to integrate your INSTAR IP camera into the automation system.`}</p>
    <p>{`To be able to communicate with our camera, we are going to translate its `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI Command Interface`}</a>{` to a M2M (Machine-to-Machine) communication protocol called `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/"
      }}>{`MQTT`}</a>{` using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/"
      }}>{`Node-RED`}</a>{`.`}</p>
    <p>{`ioBroker runs in the `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js Runtime`}</a>{` and is OS-agnostic - it can be installed under most operating systems - we are going to show the installation process `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Windows_10_Installation/"
      }}>{`under Windows`}</a>{` below. But ioBroker is clearly meant to be run on a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/Raspian_Preparation/"
      }}>{`Raspberry Pi (Rev3)`}</a>{`.`}</p>
    <h2 {...{
      "id": "iobroker-tutorials",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-tutorials",
        "aria-label": "iobroker tutorials permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Tutorials`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Windows_10_Installation/"
        }}>{`Installation under Windows 10 (optional)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/Raspian_Preparation/"
        }}>{`Setup your Raspberry Pi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Installation/"
        }}>{`IOBroker Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/"
        }}>{`IOBroker Setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/Visualisation/"
        }}>{`Visualisation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/"
        }}>{`Visualisation & motionEye`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      